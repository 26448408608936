<template>
  <div>
    <div class="d-flex align-center mb-4">
      <v-avatar
        color="#dedede"
        class="member-card__avatar"
        darken
        size="40"
        elevation="1"
      >
        <img v-if="avatar" :src="avatar" :alt="fullName" />
        <v-icon v-else color="white" size="24">mdi-account</v-icon>
      </v-avatar>
      <div class="d-flex align-center ml-2">
        <div>
          <p class="title">
            {{ fullName || "#" + participant.user.id }}
          </p>
          <div class="body-2">Talent ID: {{ participant.user.id }}</div>
        </div>
      </div>
    </div>

    <option-row title="IDs участника:">
      <template v-for="(item, idx) of participant.ids">
        <span :key="`${item}--${idx}`">{{ item }}</span
        ><span
          v-if="idx + 1 !== participant.ids.length"
          :key="item + 'commma' + idx"
          >,
        </span>
      </template>
    </option-row>

    <option-row
      v-if="participant.mentors.length"
      :title="`${participant.mentors.length > 1 ? 'Наставники' : 'Наставник'}:`"
    >
      <template v-for="(itm, idx) of participant.mentors">
        <a
          :key="`${itm}--${idx}`"
          class="link"
          href="#"
          @click.stop.prevent="openDialog(itm)"
          >{{ mentors[itm] || itm }}</a
        ><span
          v-if="idx + 1 !== participant.mentors.length"
          :key="itm + 'commma' + idx"
          >,
        </span>
      </template>
    </option-row>

    <option-row
      v-if="participant.events.length"
      :title="`${
        participant.events.length > 1 ? 'Мероприятия' : 'Мероприятие'
      }:`"
    >
      <template v-for="(event, i) of participant.events">
        <span :key="`${event}--${i}`">{{ events[event] || event }}</span
        ><span
          v-if="i + 1 !== participant.events.length"
          :key="event + 'commma' + i"
          >,
        </span>
      </template>
    </option-row>
  </div>
</template>
<script>
import { pathJoin } from "@/api/utils";
import { talentClient } from "@/api";
export default {
  name: "ParticipantCard",
  props: {
    participant: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mentors: {},
      events: {},
    };
  },
  computed: {
    avatar() {
      const {
        participant: { user },
      } = this;
      if (!user?.avatar) return "";
      if (user.avatar.startsWith("https")) {
        return user.avatar;
      }
      return pathJoin(process.env.VUE_APP_AVATAR_S3, user.avatar);
    },
    fullName() {
      const {
        participant: { user },
      } = this;
      if (!user) return "";
      return [user.last_name, user.first_name, user.middle_name]
        .filter(Boolean)
        .join(" ");
    },
  },
  created() {
    this.getUsers();
    this.getEvents();
  },
  methods: {
    async getUsers() {
      if (!this.participant.mentors.length) return;
      try {
        const { data } = await talentClient({
          method: "GET",
          url: `users/`,
          params: {
            ids: this.participant.mentors.join(","),
          },
        });
        if (data.results?.length) {
          this.mentors = data.results.reduce((acc, itm) => {
            acc[itm.id] = this.getFullName(itm);
            return acc;
          }, {});
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getEvents() {
      if (!this.participant.events.length) return;
      try {
        const { data } = await talentClient({
          method: "GET",
          url: `events/`,
          params: {
            ids: this.participant.events.join(","),
          },
        });
        if (data.results?.length) {
          this.events = data.results.reduce((acc, itm) => {
            acc[itm.id] = itm.title || itm.alt_title;
            return acc;
          }, {});
        }
      } catch (error) {
        console.error(error);
      }
    },
    getFullName(user) {
      if (!user) return "";
      return [user.last_name, user.first_name, user.middle_name]
        .filter(Boolean)
        .join(" ");
    },
    openDialog(userId) {
      if (userId) {
        this.$emit("openDialog", userId);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  color: inherit !important;
  transition: color 0.3s;
  max-width: 600px;
  margin-bottom: 0;
}
</style>
