<template>
  <div>
    <participants-section
      :participants="participants"
      @setParticipantsPage="getParticipants"
    />
  </div>
</template>
<script>
import { talentClient } from "@/api";
import { initialListingModel } from "@/utils";
import ParticipantsSection from "@/components/organizations/ParticipantsSection.vue";
export default {
  name: "OrganizationAdminsPage",
  components: { ParticipantsSection },
  data() {
    return {
      participants: initialListingModel(10),
    };
  },
  computed: {
    organization() {
      return this.$store.state.organizations.organization;
    },
  },
  watch: {
    organization: {
      handler(val) {
        if (val?.id) {
          this.getParticipants(this.participants.page);
        }
      },
    },
  },
  activated() {
    if (this.organization) {
      this.getParticipants(this.participants.page);
    }
  },
  methods: {
    async getParticipants(page = 1) {
      const adminId = this.organization?.admin_users?.[0]?.user_id;
      if (this.participants.pending || !adminId) return;
      this.participants.error = "";
      this.participants.pending = true;
      this.participants.page = page;
      try {
        const { data } = await talentClient({
          method: "GET",
          url: `users/${adminId}/organizations/${this.$route.params.orgId}/member/`,
          params: {
            limit: this.participants.limit,
            offset: (this.participants.page - 1) * this.participants.limit,
            only_one_per_user: true,
          },
        });
        this.participants.list = data.results;
        this.participants.total = data.count;
        this.participants.pagesCount = Math.ceil(
          data.count / this.participants.limit
        );
      } catch (error) {
        console.log("error", error);
        this.participants.error = error.message;
      }
      this.participants.pending = false;
    },
  },
};
</script>
